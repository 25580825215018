import { sdk } from "@gc/ipecs-web-sdk";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Accordion,
  Button,
  Container,
  Dropdown,
  Form,
  Icon,
  Segment,
} from "semantic-ui-react";
import { logout, updateMe } from "../api/auth";
import { generateAvatar } from "../api/avatar";
import Avatar from "../components/Avatar";
import PageLoader from "../components/PageLoader";
import config from "../config";
import { storageKeys } from "../constants";
import { useAuth } from "../contexts/AuthContext";
import { useCalls } from "../contexts/CallsContext";
import { useDevices } from "../contexts/DevicesContext";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      64,
      64,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
    );
  });

const actionToIndex = [
  { index: 0, action: "avatar" },
  { index: 1, action: "devices" },
  { index: 2, action: "presence" },
  { index: 3, action: "incoming-call-rules" },
  { index: 4, action: "notifications" },
  { index: 5, action: "call-recording" },
  { index: 6, action: "crm-settings" },
  { index: 7, action: "logout" },
];

function Settings() {
  const { action } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const { apiUser } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const _index = actionToIndex.filter((x) => x.action === action);
    setActiveIndex(_index.length > 0 ? _index[0].index : 0);
  }, [action]);

  const handleClick = (_, titleProps) => {
    const { index } = titleProps;
    const _index = actionToIndex.filter((x) => x.index === index);
    if (_index.length > 0) {
      navigate(`/settings/${_index[0].action}`);
    }
  };

  return (
    <Container>
      <Segment basic>
        <Accordion fluid styled>
          <AccordionItem
            index={0}
            action="devices"
            activeIndex={activeIndex}
            handleClick={handleClick}
            title={
              <>
                <Icon name="photo" /> Avatar
              </>
            }
          >
            <AvatarSettings />
          </AccordionItem>

          <AccordionItem
            index={1}
            activeIndex={activeIndex}
            handleClick={handleClick}
            title={
              <>
                <Icon name="phone" /> Devices
              </>
            }
          >
            <Devices />
          </AccordionItem>

          <AccordionItem
            index={2}
            activeIndex={activeIndex}
            handleClick={handleClick}
            title={
              <>
                <Icon name="calendar" /> Presence
              </>
            }
          >
            <PresenceSettings />
          </AccordionItem>

          <AccordionItem
            index={3}
            activeIndex={activeIndex}
            handleClick={handleClick}
            title={
              <>
                <Icon name="mail forward" /> Incoming Call Rules
              </>
            }
          >
            <IncomingCallRules />
          </AccordionItem>

          <AccordionItem
            index={4}
            activeIndex={activeIndex}
            handleClick={handleClick}
            title={
              <>
                <Icon name="bell" /> Notifications
              </>
            }
          >
            <Notifications />
          </AccordionItem>

          {apiUser.is_admin && (
            <AccordionItem
              index={5}
              activeIndex={activeIndex}
              handleClick={handleClick}
              title={
                <>
                  <Icon name="dot circle outline" /> Call Recording
                </>
              }
            >
              <CallRecording />
            </AccordionItem>
          )}

          <AccordionItem
            index={6}
            activeIndex={activeIndex}
            handleClick={handleClick}
            title={
              <>
                <Icon name="handshake" /> CRM Settings
              </>
            }
          >
            <CrmSettings />
          </AccordionItem>

          <AccordionItem
            index={7}
            activeIndex={activeIndex}
            handleClick={handleClick}
            title={
              <>
                <Icon name="sign-out" /> Logout
              </>
            }
          >
            <Logout />
          </AccordionItem>
        </Accordion>
      </Segment>

      <div style={{ textAlign: "center", marginTop: "36px" }}>
        <a href={config.privacy_policy_url} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </div>
    </Container>
  );
}

function AccordionItem({ index, activeIndex, handleClick, title, children }) {
  return (
    <>
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        onClick={handleClick}
      >
        {title}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        {children}
      </Accordion.Content>
    </>
  );
}

function AvatarSettings() {
  const { user } = sdk;
  const { ipecsUser, setIpecsUser } = useAuth();
  const [file, setFile] = useState();
  const [avatarFromText, setAvatarFromText] = useState(false);
  const [avatarText, setAvatarText] = useState("");
  const [avatarTextLength, setAvatarTextLength] = useState("2");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef();

  const handleChangeFile = async (e) => {
    const _file = await resizeFile(e.target.files[0]);

    if (_file.size > 13000) {
      toast("File is too large, it should be 13KB or smaller", {
        type: "error",
      });
    } else {
      setFile(_file);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (avatarFromText) {
        if (avatarText.length === 0) {
          toast("Please enter some text to generate the avatar from", {
            type: "error",
          });
          setLoading(false);
          return;
        } else {
          await user.setMyInfo({
            file: await generateAvatar(avatarText, avatarTextLength)
              .then((r) => new Blob([r.data], { type: "image/png" }))
              .catch((e) => console.error(e)),
          });
        }
      } else {
        await user.setMyInfo({ file: file });
      }

      setIpecsUser(await user.getMyInfo());
      setAvatarFromText(false);
      setAvatarText("");
      setFile();
      toast("Avatar updated", { type: "success" });
    } catch {
      toast("Failed to update avatar", { type: "error" });
    }
    setLoading(false);
  };

  const getAvatarSrc = () => {
    if (file !== undefined) {
      return file
        ? URL.createObjectURL(file)
        : `initial:${ipecsUser.myInfo.name}`;
    }

    if (avatarFromText) {
      return `https://eu.ui-avatars.com/api/?name=${avatarText}&length=${avatarTextLength}size=200`;
    }

    return ipecsUser.myInfo.avatar;
  };

  return (
    <Container>
      <Segment basic>
        <Avatar
          avatar
          src={getAvatarSrc()}
          style={{ fontSize: 20, marginRight: "24px" }}
        />

        {avatarFromText || file !== undefined ? (
          <Button.Group>
            <Button
              color="red"
              size="tiny"
              content="Cancel"
              onClick={() => {
                setAvatarFromText(false);
                setFile();
              }}
              disabled={loading}
            />
            <Button.Or />
            <Button
              color="green"
              content="Save"
              onClick={handleSave}
              loading={loading}
            />
          </Button.Group>
        ) : (
          <Button.Group style={{ flexWrap: "wrap" }}>
            <Button
              color="red"
              size="tiny"
              content="Delete avatar"
              onClick={() => setFile("")}
              disabled={loading}
            />
            <Button.Or />
            <Button
              primary
              content="Choose new image"
              onClick={() => fileInputRef.current.click()}
            />
            <Button.Or />
            <Button
              primary
              content="Generate from text"
              onClick={() => setAvatarFromText(true)}
            />
          </Button.Group>
        )}

        {avatarFromText && (
          <Form style={{ marginTop: "24px" }}>
            <Form.Field>
              <input
                placeholder="Text or initials to generate avatar from"
                value={avatarText}
                onChange={(e) => {
                  setAvatarText(e.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                placeholder="# of characters to use"
                fluid
                selection
                value={avatarTextLength}
                options={[
                  { key: "2", value: "2", text: "2 characters" },
                  { key: "3", value: "3", text: "3 characters" },
                ]}
                onChange={(_, data) => setAvatarTextLength(data.value)}
              />
            </Form.Field>
          </Form>
        )}

        <input
          ref={fileInputRef}
          accept="image/png, image/jpeg"
          type="file"
          hidden
          onChange={handleChangeFile}
        />
      </Segment>
    </Container>
  );
}

function Devices() {
  const {
    handleInitDevices,
    uniqueDevices: devices,
    audioInput,
    audioOutput,
    videoInput,
    ringOutput,
    setAudioOutputDevice,
    setAudioInputDevice,
    setVideoInputDevice,
    setRingOutputDevice,
  } = useDevices();

  useEffect(() => {
    handleInitDevices();
    const devicesInterval = setInterval(handleInitDevices, 10000);
    return () => clearInterval(devicesInterval);
  }, [handleInitDevices]);

  const handleChangeAudioOutput = (_, data) => {
    setAudioOutputDevice(data.value)
      .then(() => toast("Audio output updated", { type: "success" }))
      .catch(() =>
        toast("Failed to set audio output device", { type: "error" }),
      );
  };

  const handleChangeRingOutput = (_, data) => {
    setRingOutputDevice(data.value)
      .then(() => toast("Ring output updated", { type: "success" }))
      .catch(() =>
        toast("Failed to set ring output device", { type: "error" }),
      );
  };

  const handleChangeAudioInput = (_, data) => {
    setAudioInputDevice(data.value)
      .then(() => toast("Audio input updated", { type: "success" }))
      .catch(() =>
        toast("Failed to set audio input device", { type: "error" }),
      );
  };

  const handleChangeVideoInput = (_, data) => {
    setVideoInputDevice(data.value)
      .then(() => toast("Video input updated", { type: "success" }))
      .catch(() =>
        toast("Failed to set video input device", { type: "error" }),
      );
  };

  if (devices.length === 0) return <PageLoader>Devices loading...</PageLoader>;

  return (
    <Container>
      <Segment basic>
        <Form>
          <Form.Field>
            <label>Audio Output</label>
            <Dropdown
              placeholder="System Default"
              fluid
              selection
              value={audioOutput}
              options={devices
                .filter((x) => x.kind === "audiooutput")
                .map((x) => {
                  return {
                    key: x.deviceId,
                    text: x.label,
                    value: x.deviceId,
                  };
                })}
              onChange={handleChangeAudioOutput}
            />
          </Form.Field>

          <Form.Field>
            <label>Ring Output</label>
            <Dropdown
              placeholder="System Default"
              fluid
              selection
              value={ringOutput}
              options={devices
                .filter((x) => x.kind === "audiooutput")
                .map((x) => {
                  return {
                    key: x.deviceId,
                    text: x.label,
                    value: x.deviceId,
                  };
                })}
              onChange={handleChangeRingOutput}
            />
          </Form.Field>

          <Form.Field>
            <label>Audio Input</label>
            <Dropdown
              placeholder="System Default"
              fluid
              selection
              value={audioInput}
              options={devices
                .filter((x) => x.kind === "audioinput")
                .map((x) => {
                  return {
                    key: x.deviceId,
                    text: x.label,
                    value: x.deviceId,
                  };
                })}
              onChange={handleChangeAudioInput}
            />
          </Form.Field>

          <Form.Field>
            <label>Video Input</label>
            <Dropdown
              placeholder="System Default"
              fluid
              selection
              value={videoInput}
              options={devices
                .filter((x) => x.kind === "videoinput")
                .map((x) => {
                  return {
                    key: x.deviceId,
                    text: x.label,
                    value: x.deviceId,
                  };
                })}
              onChange={handleChangeVideoInput}
            />
          </Form.Field>
        </Form>
      </Segment>
    </Container>
  );
}

function CrmSettings() {
  const { apiUser, setApiUser } = useAuth();

  const [phoneFormat, setPhoneFormat] = useState(apiUser.crm_phone_format);
  const [autoOpenCrmRecord, setAutoOpenCrmRecord] = useState(
    apiUser.auto_open_crm_record,
  );
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setChanged(
      phoneFormat !== apiUser.crm_phone_format ||
        autoOpenCrmRecord !== apiUser.auto_open_crm_record,
    );
  }, [phoneFormat, autoOpenCrmRecord, apiUser, setChanged]);

  const handleChangeCrmPhoneFormat = (_, data) => {
    setPhoneFormat(data.value);
  };

  const handleChangeAutoOpenCrm = (_, data) => {
    setAutoOpenCrmRecord(data.value);
  };

  const handleSubmit = () => {
    setSaving(true);
    updateMe({
      crm_phone_format: phoneFormat,
      auto_open_crm_record: autoOpenCrmRecord,
    }).then((res) => {
      setSaving(false);
      setApiUser(res.data.data);
      toast("CRM settings updated", { type: "success" });
    });
  };

  return (
    <Container>
      <Segment basic>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Phone Number Format</label>
            <p>
              The format you are storing numbers in on the CRM system(s) that
              are integrated with Connect (using the Apps page).
            </p>
            <p>
              An example of internaional (E.164) format is +442030123123
              <br />
              An example of national format is 02030123123
            </p>

            <Dropdown
              placeholder="International (E.164)"
              fluid
              selection
              value={phoneFormat}
              options={[
                { key: "E164", value: "E164", text: "International (E.164)" },
                {
                  key: "NATIONAL",
                  value: "NATIONAL",
                  text: "National (no spaces)",
                },
                {
                  key: "NATIONAL_WITH_SPACES",
                  value: "NATIONAL_WITH_SPACES",
                  text: "National (with spaces)",
                },
                {
                  key: "INTERNATIONAL_WITH_SPACES",
                  value: "INTERNATIONAL_WITH_SPACES",
                  text: "International (with spaces)",
                },
              ]}
              onChange={handleChangeCrmPhoneFormat}
            />
          </Form.Field>

          <Form.Field>
            <label>
              Automatically open CRM records when calls are answered?
            </label>
            <p>
              When you receive an inbound call a button will be displayed which
              will link to your CRM contact record.
              <br />
              If this enabled the link will automatically be opened when you
              answer the call.
            </p>
            <Dropdown
              placeholder="No"
              fluid
              selection
              value={autoOpenCrmRecord}
              options={[
                { key: "Yes", value: true, text: "Yes" },
                { key: "No", value: false, text: "No" },
              ]}
              onChange={handleChangeAutoOpenCrm}
            />
          </Form.Field>

          <Button type="submit" loading={saving} disabled={!changed} primary>
            Save
          </Button>
        </Form>
      </Segment>
    </Container>
  );
}

function PresenceSettings() {
  const { apiUser, setApiUser } = useAuth();
  const [presenceAutoUpdate, setPresenceAutoUpdate] = useState(
    apiUser.presence.auto_update,
  );
  const [presenceMeetingName, setPresenceMeetingName] = useState(
    apiUser.presence.meeting_name ? "1" : "0",
  );
  const [microsoftPresenceAutoUpdate, setMicrosoftPresenceAutoUpdate] =
    useState(apiUser.presence.presence_update_from_ms);

  const handleChangeAutoPresence = (_, data) => {
    const _value = data.value === "1" ? "busy" : "";
    setPresenceAutoUpdate(_value);
    updateMe({
      presence_auto_update: _value,
      presence_meeting_name: presenceMeetingName,
      presence_update_from_ms: microsoftPresenceAutoUpdate,
    }).then((res) => setApiUser(res.data.data));
  };

  const handleChangePresenceAutoUpdateValue = (_, data) => {
    setPresenceAutoUpdate(data.value);
    updateMe({
      presence_auto_update: data.value,
      presence_meeting_name: presenceMeetingName,
      presence_update_from_ms: microsoftPresenceAutoUpdate,
    }).then((res) => setApiUser(res.data.data));
  };

  const handleChangePresenceMeetingName = (_, data) => {
    setPresenceMeetingName(data.value);
    updateMe({
      presence_auto_update: presenceAutoUpdate,
      presence_meeting_name: data.value,
      presence_update_from_ms: microsoftPresenceAutoUpdate,
    }).then((res) => setApiUser(res.data.data));
  };

  const handleChangeMicrosoftPresenceAutoUpdate = (_, data) => {
    setMicrosoftPresenceAutoUpdate(data.value);
    updateMe({
      presence_auto_update: presenceAutoUpdate,
      presence_meeting_name: presenceMeetingName,
      presence_update_from_ms: data.value,
    }).then((res) => setApiUser(res.data.data));
  };

  return (
    <Container>
      {apiUser.presence.locked && (
        <p>
          <em>Your presence settings are locked by an admin.</em>
        </p>
      )}
      <Segment basic>
        <Form>
          <Form.Field>
            <label>Auto Update Presence</label>
            <Dropdown
              disabled={apiUser.presence.locked}
              value={presenceAutoUpdate.length > 0 ? "1" : "0"}
              onChange={handleChangeAutoPresence}
              fluid
              selection
              options={[
                {
                  key: "0",
                  text: "Don't update my presence when in a meeting",
                  value: "0",
                },
                {
                  key: "1",
                  text: "Automatically update my presence when in a meeting",
                  value: "1",
                },
              ]}
            />
          </Form.Field>

          {presenceAutoUpdate.length > 0 && (
            <>
              <Form.Field>
                <label>When in a meeting set my presence to</label>
                <Dropdown
                  disabled={apiUser.presence.locked}
                  value={presenceAutoUpdate}
                  onChange={handleChangePresenceAutoUpdateValue}
                  fluid
                  selection
                  options={[
                    { key: "busy", text: "Busy", value: "busy" },
                    { key: "dnd", text: "DND", value: "dnd" },
                  ]}
                />
              </Form.Field>

              <Form.Field>
                <label>
                  Include meeting name (when not private) in presence status for
                  others to see?
                </label>
                <Dropdown
                  disabled={apiUser.presence.locked}
                  value={presenceMeetingName}
                  onChange={handleChangePresenceMeetingName}
                  fluid
                  selection
                  options={[
                    { key: "0", text: "No", value: "0" },
                    { key: "1", text: "Yes", value: "1" },
                  ]}
                />
              </Form.Field>
            </>
          )}
        </Form>
      </Segment>

      <Segment basic>
        <Form>
          <Form.Field>
            <label>Auto Update Presence From Microsoft Teams</label>
            <Dropdown
              disabled={apiUser.presence.locked}
              value={microsoftPresenceAutoUpdate > 0 ? "1" : "0"}
              onChange={handleChangeMicrosoftPresenceAutoUpdate}
              fluid
              selection
              options={[
                {
                  key: "0",
                  text: "Don't update my presence based on my Teams status",
                  value: "0",
                },
                {
                  key: "1",
                  text: "Automatically update my presence based on my Teams status",
                  value: "1",
                },
              ]}
            />
          </Form.Field>
        </Form>
      </Segment>
    </Container>
  );
}

function Notifications() {
  const [enabled, setEnabled] = useState();
  const [requested, setRequested] = useState(false);
  const [error, setError] = useState(false);
  const [testSending, setTestSending] = useState(false);
  const [testSent, setTestSent] = useState(false);

  useEffect(() => {
    setEnabled(Notification.permission === "granted");
  }, []);

  const handleRequestPermission = () => {
    setRequested(true);
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        setEnabled(true);
      } else {
        setEnabled(false);
        setError(true);
      }
    });
  };

  const handleTest = () => {
    setTestSending(true);
    setTestSent(false);
    setTimeout(() => {
      const notification = new Notification("Test Notification", {
        body: "From Cosoft Connect",
        icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
      });
      notification.onclick = () => window.focus();
      setTestSending(false);
      setTestSent(true);
    }, 1000);
  };

  return (
    <div>
      <p>
        Use browser notifications to get notified about incoming calls and
        messages
      </p>

      {enabled ? (
        <div>
          <p>
            <Icon name="check circle" color="green" /> You currently have
            browser notifications enabled.
          </p>

          <p>
            <Button primary onClick={handleTest} loading={testSending}>
              Send test notification
            </Button>
          </p>

          {testSent && (
            <p>
              <Icon name="info circle" /> If you do not see a notification
              please check your system notifications settings. It is possible
              that notifications for the browser you're using are being blocked
              or hidden.
            </p>
          )}
        </div>
      ) : (
        <div>
          <Button
            primary
            onClick={handleRequestPermission}
            style={{ marginBottom: "16px" }}
          >
            Enable browser notifications
          </Button>

          {error && (
            <p>
              Unable to enable notifications, it is being blocked by your
              browser. Please check your browser settings.
            </p>
          )}

          {!error && requested && (
            <p>
              Permission requested, if you do not see a request popup please
              check your browser settings, as it may be blocking requests.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

function Logout() {
  const queryClient = useQueryClient();
  const { auth } = sdk;
  const { setApiUser, setIpecsUser, setIpecsAuthenticated, setLoggingOut } =
    useAuth();
  const { hasActiveCall } = useCalls();

  const handleLogout = async () => {
    setLoggingOut(true);
    try {
      await auth.logout();
    } catch (e) {
      if (e.message !== "E_UNKNOWN_TOKEN") {
        throw e;
      }
    }
    await logout();
    queryClient.clear();
    localStorage.removeItem(storageKeys.IPECS_LOGIN);
    localStorage.removeItem(storageKeys.ACD_AGENT_ID);
    localStorage.removeItem(storageKeys.ACD_ENABLED);
    localStorage.removeItem(storageKeys.IPECS_AUTH);
    setIpecsAuthenticated(false);
    setIpecsUser();
    setApiUser();
    setLoggingOut(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "260px",
        gap: "16px",
      }}
    >
      <Button color="red" onClick={handleLogout} disabled={hasActiveCall()}>
        Confirm Logout
      </Button>
    </div>
  );
}

function IncomingCallRules() {
  const { setting } = sdk;

  const [anonymousCallReject, setAnonymousCallReject] = useState();
  const [anonymousCallRejectServiceType, setAnonymousCallRejectServiceType] =
    useState();
  const [callForward, setCallForward] = useState();
  const [callForwardServiceType, setCallForwardServiceType] = useState();
  const [callForwardDestination, setCallForwardDestination] = useState();
  const [saving, setSaving] = useState(false);

  const anonymousCallRejectServiceTypes = [
    { key: "disable", label: "No" },
    { key: "enable", label: "Yes" },
  ];

  const callForwardServiceTypes = [
    { key: "disable", label: "Disabled" },
    { key: "uncondition", label: "Unconditional" },
    { key: "busy", label: "Busy" },
    { key: "busy_noans", label: "Busy/No Answer" },
    { key: "no_answer", label: "No Answer" },
  ];

  const handleInitSettings = useCallback(async () => {
    try {
      const anonymousRes = await setting.getAnonymousCallReject();
      const callForwardRes = await setting.getCallForward();

      setAnonymousCallReject(anonymousRes);
      setAnonymousCallRejectServiceType(anonymousRes.serviceType);
      setCallForward(callForwardRes);
      setCallForwardServiceType(callForwardRes.serviceType);
      setCallForwardDestination(callForwardRes.destination);
    } catch (e) {
      toast("Failed to load call forward settings", { type: "error" });
    }
  }, [setting]);

  useEffect(() => {
    handleInitSettings();
  }, [handleInitSettings]);

  const handleSubmit = async () => {
    setSaving(true);
    try {
      if (anonymousCallReject.serviceType !== anonymousCallRejectServiceType) {
        await setting.setAnonymousCallReject({
          serviceType: anonymousCallRejectServiceType,
        });
        setAnonymousCallReject(await setting.getCallForward());
      }

      if (
        callForward.serviceType !== callForwardServiceType ||
        callForward.destination !== callForwardDestination
      ) {
        await setting.setCallForward({
          serviceType: callForwardServiceType,
          destination: callForwardDestination,
        });
        setCallForward(await setting.getCallForward());
      }

      toast("Incoming call rules updated", { type: "success" });
    } catch (e) {
      toast(
        "Failed to update incoming call rules, please ensure you have entered a valid number",
        { type: "error" },
      );
    }
    setSaving(false);
  };

  if (anonymousCallReject === undefined || callForward === undefined)
    return null;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <label>Reject Anonymous Calls?</label>
        <Dropdown
          placeholder="Reject anonymousc calls?"
          fluid
          selection
          value={anonymousCallRejectServiceType}
          options={anonymousCallRejectServiceTypes.map((x) => ({
            key: x.key,
            value: x.key,
            text: x.label,
          }))}
          onChange={(_, data) => setAnonymousCallRejectServiceType(data.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Call Forwarding</label>
        <Dropdown
          placeholder="Call Forward Setting"
          fluid
          selection
          value={callForwardServiceType}
          options={callForwardServiceTypes.map((x) => ({
            key: x.key,
            value: x.key,
            text: x.label,
          }))}
          onChange={(_, data) => setCallForwardServiceType(data.value)}
        />
      </Form.Field>
      {callForwardServiceType !== "disable" && (
        <Form.Field>
          <label>Forward Destination</label>
          <input
            placeholder="Forward destination"
            value={callForwardDestination}
            onChange={(e) => setCallForwardDestination(e.target.value)}
          />
        </Form.Field>
      )}

      <Button
        type="submit"
        loading={saving}
        disabled={
          anonymousCallReject.serviceType === anonymousCallRejectServiceType &&
          callForward.serviceType === callForwardServiceType &&
          callForward.destination === callForwardDestination
        }
        primary
      >
        Save
      </Button>
    </Form>
  );
}

function CallRecording() {
  const { apiUser, setApiUser } = useAuth();

  const [recordingOption, setRecordingOption] = useState(apiUser.record_calls);
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setChanged(recordingOption !== apiUser.record_calls);
  }, [apiUser.record_calls, recordingOption]);

  const handleChangeRecordingOption = (_, data) => {
    setRecordingOption(data.value);
  };

  const handleSubmit = () => {
    setSaving(true);
    updateMe({
      record_calls: recordingOption,
    }).then((res) => {
      setSaving(false);
      setApiUser(res.data.data);
      toast("Call recording options updated", { type: "success" });
    });
  };

  return (
    <Container>
      <Segment basic>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Record calls</label>
            <p>
              iPECS records all calls by default. If you only want to record
              certain calls, you can choose that option here. This will only
              affect calls made in soft phone mode.
            </p>
            <p>
              Connect will automatically toggle off recording for the direction
              you don't want to record after the call has connected.
            </p>
            <p>
              NOTE: This is a setting for your entire tenant. As an admin user,
              the setting you choose here will affect all your users.
            </p>
            <Dropdown
              placeholder="All calls"
              fluid
              selection
              value={recordingOption}
              options={[
                { key: "ALL", value: "ALL", text: "All calls" },
                {
                  key: "INBOUND",
                  value: "INBOUND",
                  text: "Inbound calls only",
                },
              ]}
              onChange={handleChangeRecordingOption}
            />
          </Form.Field>

          <Button type="submit" loading={saving} disabled={!changed} primary>
            Save
          </Button>
        </Form>
      </Segment>
    </Container>
  );
}

export default Settings;
